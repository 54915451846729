import React from 'react';

const Footer = (props) => {
    return (
        
        <>
       <p className="text-white text-center bg-dark m-0 py-3">&copy;  SvasthyaHealth | Site by 1990 Minds</p> 
        </>
    );
};

export default Footer;