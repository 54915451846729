import React, { Component } from 'react';
import './navbar.css'
import logo from '../images/logo.png';
import {Navbar, Form, Button, FormControl, Nav} from 'react-bootstrap'
import {Link} from 'gatsby';

import { Controller, Scene } from 'react-scrollmagic';


class componentName extends Component {




    render() {


        return (  


          <>
              <div id="trigger6" />
  
              <Controller>
    <Scene
   classToggle={"", "aftersc"}
       pin={true}
       triggerHook={0}
        enabled={true}
      >




 <div  id="trigger6" className="bg-light" style={{zIndex:222, width:"100%", margin:"auto"}}  >


<Navbar  className="container" id="snav" expand="lg">
  <Navbar.Brand href="#section1">
   


    <img src={logo} alt="logo" width="80px"/>

    
    
    </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mx-auto">
      <Nav.Link className="text-dark mx-3 text-uppercase" style={{fontSize:"18px", fontFamily: 'Montserrat, sans-serif'}} href="#section1">Home</Nav.Link>
      <Nav.Link className="text-dark mx-3 text-uppercase" style={{fontSize:"18px", fontFamily: 'Montserrat, sans-serif'}} href="#features">Features</Nav.Link>
      <Nav.Link className="text-dark mx-3 text-uppercase" style={{fontSize:"18px", fontFamily: 'Montserrat, sans-serif'}} href="#gallery">Gallery</Nav.Link>
      <Nav.Link className="text-dark mx-3 text-uppercase" style={{fontSize:"18px", fontFamily: 'Montserrat, sans-serif'}} href="#team">Our Team</Nav.Link>
      <Nav.Link className="text-dark mx-3 text-uppercase" style={{fontSize:"18px", fontFamily: 'Montserrat, sans-serif'}} href="#blog">Blog</Nav.Link>
      <Nav.Link className="text-dark mx-3 text-uppercase" style={{fontSize:"18px", fontFamily: 'Montserrat, sans-serif'}} href="#contact">Contact Us</Nav.Link>

    </Nav>

  </Navbar.Collapse>
</Navbar>
</div>



         

            </Scene>
</Controller>
</>
            
        );
    }
}

export default componentName;