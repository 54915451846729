import React from 'react';
import styled from 'styled-components'
import './navbar.css'
import { MdMailOutline, MdAccountCircle } from 'react-icons/md';

const Topbar = (props) => {
    return (
        
      <TopBarWrap>
   <div className="container navs " >

<div className="row t">
<div className="col-sm-5 ">  <li><MdMailOutline/><a href="mailto:svasthya@svasthyahealth.com">svasthya@svasthyahealth.com</a> </li></div>
    <div className="col-sm-1 "></div>
    <div className="col-sm-2"></div>
    <div className="col-sm-1 "></div>
    <div className="col-sm-3 "><li><MdAccountCircle/><a className="text-white" style={{textDecoration:"none"}} href="#">Login</a> </li></div>
</div>


   </div>
      </TopBarWrap>
    );
};


const TopBarWrap = styled.div`
z-index:22 !important;
li{

color:white;
background-color:transparent;
list-style:none;
text-align:center;
padding:2px 3px;

}

.t{

  z-index:22;
  transform:translateX(-100px);

  svg{

    font-size:20px;
    transform:translateY(5px);


  }
  a{

    color:white;
  }
}

background-color:#0973BA;
@media(max-width:960px){


 display:none !important;



 
}

`
export default Topbar;